/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";


export const MonitorSCH = (perPage)=> {
    var filtro = new Filtro()
    filtro.perPage = perPage
    filtro.fields = ['id','dt_abastecimento', 'hr_chegada','motorista','carro_id','diesel','orig_dest','meta_d','meta_t', 'tp_motor']
   filtro.addOrder('dt_abastecimento','desc')
   filtro.addOrder('hr_chegada','desc')
  return filtro
}
