/* eslint-disable */
import {lista, pegaDados, processar} from '../../services/GenericAPI'
import {EventBus} from '../../helpers/event-bus'
import {Abastecimento} from "../../models/abastecimento";
import {AbastecimentoSCH} from "../../search/AbastecimentoSCH"
import {Filtro} from "../../models/filtro/filtro";
import {SetorSCH} from "../../search/SetorSCH";
import {getBaseSCH} from "../../search/BaseSCH";

const url = '/abastecimento';

const state = {
  all: [],
  allList: [],
  filtro: new Filtro(),
  abastecimento: new Abastecimento()
};

const getters = {
  pegaFiltro: (state) => {
    return state.filtro
  },
  listaAbastecimentos: (state) => {
    return state.all
  },
  listaAllAbastecimento: (state) => {
    return state.allList
  },
  pegarAbastecimento: (state) => {
    return state.abastecimento
  },
};

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['numero', 'hr_chegada', 'carro_id', 'qtd_litros_arla32', 'km_abastecimento', 'qtd_litros_diesel','linha'],
      '%'+search+'%',
      'ilike'
    )
  },
  async getAbastecimento({commit}, {id,onSucesso,onErro}) {

    try {
      let filtro = getBaseSCH('abastecimento_id', id, '=');
      const list = (await pegaDados(url+'/get', filtro)).data;
      commit('SET_ABASTECIMENTO', list.data[0]);
      onSucesso()
    } catch (error) {
      onErro()
    }

  },
  async getNumero({commit}, {item,onSucesso,onErro}) {

    try {
      const list = (await pegaDados(url+'/check', item));
      onSucesso(list.data)
    } catch (error) {
      onErro()
    }

  },
  async getMaxKm({commit}, {item,onSucesso,onErro}) {

    try {
      const list = (await pegaDados(url+'/getMaxKm', item));
      onSucesso(list.data)
    } catch (error) {
      onErro()
    }

  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', AbastecimentoSCH(perPage))
  },

  async setAllAbastecimentos ({ commit },{onSucesso,onErro}) {
    try {
      console.log('SET_ABAST.FILTRO:', state.filtro.page);
      const list = (await pegaDados(url+'/getAll', state.filtro)).data;
      commit('SET_ABASTECIMENTOS', list);
      onSucesso()
    } catch (error) {
      onErro()
    }
  },
  setAbastecimento({commit}, abastecimento) {
    commit('SET_ABASTECIMENTO', abastecimento)
  },

  async setAllListAbastecimento ({ commit },{onSucesso}) {
    try {
      const list = (await lista(url+'/all')).data;
      commit('SET_ALL_ABASTECIMENTOS', list);
      onSucesso()
    }   catch (error) {
      console.log(error);
    }

  },

  async processarAbastecimento ({ commit }, { abastecimento, acao,onSucesso,onErro }) {
    try {
      let res = await processar(url + (acao==='gravar' ? '/commit' : '/delete'), abastecimento, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }
  }
};

const mutations = {
  SET_ABASTECIMENTOS (state, abastecimento) {
    state.all = abastecimento
  },
  SET_ALL_ABASTECIMENTOS (state, abastecimento) {
    state.allList = abastecimento
  },
  SET_ABASTECIMENTO (state, abastecimento) {
    state.abastecimento = abastecimento
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
