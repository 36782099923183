/* eslint-disable */
import {v4 as uuidV4} from "uuid";

export class Horario {
  constructor () {
    this.horario_id = uuidV4()
    this.setor_id = ''
    this.hr_chegada = ''
    this.meta_motor_dianteiro =''
    this.meta_motor_traseiro = ''
    this.horarios_linhas = []
  }
}
