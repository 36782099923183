import {mapGetters} from "vuex";
import router from '../router/router'

export default {
   computed: {
      ...mapGetters('configDash', {permissao: 'getPermissao'}),
   },
   data: () => ({
      dialogPdf: false,
      show_progress: false,
      editando: false,
      form: false,
      show_list: false,
      listaItens: [],
      info_alerta: '',
      cor_alerta: 'primary',
      show_alerta: false,
      precisao: {
         decimal: ',',
         thousands: '.',
         prefix: '',
         suffix: '',
         precision: 2,
         masked: false,
         allowBlank: false,
         min: Number.MIN_SAFE_INTEGER,
         max: Number.MAX_SAFE_INTEGER
      },
   }),
   methods: {
      sair(path) {
         router.push({path: path}).catch(err => {
         })
      },
      get_pdf(response) {
         let file = new Blob([response.data], {type: 'application/pdf'})
         let fileURL = URL.createObjectURL(file)
         window.open(fileURL, '_blank')
      },
      getByType(response, tipo = 'application/pdf') {
         let file = new Blob([response.data], {type: tipo})
         let fileURL = URL.createObjectURL(file)
         window.open(fileURL, '_blank')
      },
      get_data_pdf(response, tipo = 'application/pdf') {
         let file = new Blob([response.data], {type: tipo})
         let fileURL = URL.createObjectURL(file)
         return fileURL
      },
      cloneObj(obj) {
         return JSON.parse(JSON.stringify(obj))
      },
      ordenarListaAsc(lista, chave, tipo = 'asc') {
         return lista.sort(function (a, b) {
            if (tipo === 'asc') {
               if (a[chave] > b[chave]) {
                  return 1;
               }
               if (a[chave] < b[chave]) {
                  return -1;
               }
            } else {
               if (a[chave] < b[chave]) {
                  return 1;
               }
               if (a[chave] > b[chave]) {
                  return -1;
               }
            }
            return 0;
         })
      },
      openFullscreen() {
         var element = document.documentElement;
         if (element.requestFullscreen) {
            element.requestFullscreen();
         } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
         } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen();
         } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
         }
      },
      closeFullscreen() {
         if (document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.mozFullScreenElement) {
            // can use exitFullscreen
            if (document.exitFullscreen) {
               document.exitFullscreen();
            }
            if (document.webkitExitFullscreen) {
               document.webkitExitFullscreen();
            }
            // if (document.mozCancelFullScreen)
            // {
            //    document.mozCancelFullScreen();
            // }
            // if (document.msExitFullscreen)
            // {
            //    document.msExitFullscreen();
            // }
         }


      },
      podeInserirEditar(editar) {
         if (editar) {
            return !this.permissao.editar // Pode Editar
         } else {
            return !this.permissao.inserir // Pode inserir
         }
      },
      focusInput(id) {
         let loadTime = 10;
         window.onload = function () {
            loadTime = window.performance.timing.domContentLoadedEventEnd - window.performance.timing.navigationStart;
         };
         let interval = setInterval(function () {
            let muniImpt = document.getElementById(id);
            if (muniImpt !== null) {
               muniImpt.focus()
            }
            clearInterval(interval)
         }, loadTime)
      },
      getDataAtual() {
         let d = this.zeroEsquerda(new Date().getDate(), 2)
         let m = this.zeroEsquerda(new Date().getMonth() + 1, 2)
         let a = new Date().getFullYear()
         return a + '-' + m + '-' + d
      },

      getHora() {
         let h = this.zeroEsquerda(new Date().getHours(), 2)
         let m = this.zeroEsquerda(new Date().getMinutes(), 2)
         let s = this.zeroEsquerda(new Date().getSeconds(), 2)
         return h + ':' + m+':'+s
      },
      gerarData(tipo = true, dia, mes, ano) {
         var data = new Date()
         let d = this.validarCampo(dia) ? dia : data.getDate()
         let m = this.validarCampo(mes) ? mes : (data.getMonth() + 1)
         let a = this.validarCampo(ano) ? ano : data.getFullYear()

         if (tipo) {
            return a + '-' + this.zeroEsquerda(m, 2) + '-' + this.zeroEsquerda(d, 2)
         } else {
            return a + '-' + m + '-' + d
         }
      },
      getUltimoDia(data) {
         let d = new Date(data);
         let ultimoDia = new Date(d.getFullYear(), (d.getMonth() + 1), 0);
         return this.setDataAtual(ultimoDia)
      },
      setDataAtual(data) {
         let d = this.zeroEsquerda(data.getDate(), 2)
         let m = this.zeroEsquerda(data.getMonth() + 1, 2)
         let a = data.getFullYear()
         return a + '-' + m + '-' + d
      },
      getDataHora(dt){
         var h = this.extrairData2(dt,1)
         h = this.validarCampo(h) ? h : '00:00:00'
         return this.inverterData(this.extrairData2(dt)) + ' ' + h
      },

      inverterData(newData) {
         var xvals = '';
         if (this.validarCampo(newData)) {
            xvals = newData.split('-');
            return xvals[2] + '/' + xvals[1] + '/' + xvals[0]
         } else {
            return xvals
         }
      },
      zeroEsquerda(value, totalWidth, paddingChar) {
         var length = totalWidth - value.toString().length + 1
         return Array(length).join(paddingChar || '0') + value
      },
      getValueByKey(item, key) {
         if (item[key] === undefined) {
            return item
         } else {
            return item[key]
         }
      },
      setZeros(v, tam = 5) {
         if (this.validarCampo(v)) {
            var valor = parseInt(v) + ''
            return this.zeroEsquerda(valor, tam)
         }
      },
      logs(obj) {
         if (this.validarCampo(obj)) {
            return JSON.parse(JSON.stringify(obj))
         } else {
            return obj
         }
      },
      itens(item) {
         this.listaItens = item
      },
      closeDialog(dialog) {
         dialog.close()
      },
      onCancel() {
         this.value = 'Não'
      },
      open_alerta(text, cor) {
         this.info_alerta = text
         this.cor_alerta = cor
         this.show_alerta = true
         var _this = this
         var intervalo = setInterval(function () {
            _this.show_alerta = false
            clearInterval(intervalo)
         }, 6000)
      },
      toDecimal(v, tam = 2, valida_nan = false) {
         try {
            if (valida_nan) {
               if (isNaN(parseFloat(v))) {
                  return 0
               } else {
                  if (this.validarCampo(v)) {
                     return parseFloat(parseFloat(v).toFixed(tam))
                  } else {
                     return 0
                  }
               }
            } else {
               let ret = 0;
               if (this.validarCampo(v)) {
                  if (!this.validarCampo(tam)) tam = 2;
                  ret = parseFloat(parseFloat(v).toFixed(tam))
               }
               return ret
            }
         } catch (e) {
            console.log(e)
         }
      },
      fechar_alerta() {
         this.show_alerta = false
      },

      validarAtertaFocus(campo, id, cor = 'red', txt) {
         if (this.validarCampo(campo)) {
            this.open_alerta('O campo ' + campo.toUpperCase() + ' de  é obrigatório', cor)
         } else {
            this.open_alerta(txt, cor)
         }
         this.focusInput(id)
      },

      validarCampo(campo) {
         if (campo !== undefined && campo !== null && campo !== '') {
            return true
         } else {
            return false
         }
      },
      scrollTop() {
         var elmnt = document.getElementById('inspire')
         elmnt.scrollIntoView({behavior: 'smooth'})
      },
      enterTab(InputId, e) {
         e = e || window.event;
         const code = e.which || e.keyCode;
         if (code === 13) {
            var el = document.getElementById(InputId);
            if (el !== null) {
               el.focus();

            }
            // e.preventDefault()
         }
      },
      cortarString(str, tam) {
         if (this.validarCampo(str)) {
            if (str.length > tam) {
               str = str.slice(0, tam) + '...'
            }
         }
         return str
      },
      onEnterTab(valor, id, next, event) {
         if (!this.validarCampo(valor)) {
            this.focusInput(id)
         } else {
            this.enterTab(next, event)
         }
      },
      mascaraDecimal(valor, decimais) {
         const numDecimais = (decimais !== null && decimais !== undefined) ? decimais : 2
         if (valor !== undefined && valor !== null) {
            return Number(valor).toLocaleString('pt-br', {minimumFractionDigits: numDecimais})
         }
         return ''
      },

      mobileModal(size) {
         var width = window.screen.width
         if (width <= 400) {
            size = '100%'
         } else if (width >= 400 && width <= 600) {
            size = '90%'
         } else if (width >= 601 && width <= 900) {
            size = '80%'
         } else if (width >= 901 && width <= 1300) {
            size = '70%'
         }
         return size
      },

      returnValue(valor, ret = 0) {
         return this.validarCampo(valor) ? valor : ret
      },
      getData(data) {
         return this.validarCampo(data) ? (this.inverterData(data.substr(0, 10)) + '' + data.substr(10, 9)) : ""
      },
      extrairData(data) {
         let date = new Date(data.substr(0, 10));
         let d = this.zeroEsquerda(date.getDate(), 2)
         let m = this.zeroEsquerda(date.getMonth() + 1, 2)
         let a = date.getFullYear()
         return a + '-' + m + '-' + d
      },

      compararData(dt_inicial, dt_final) {
         let g1 = new Date(dt_inicial);
         let g2 = new Date(dt_final);
         return g1.getTime() > g2.getTime()
      },

      extrairData2(data,index=0) {
         let dt = ''
         if (this.validarCampo(data)) {
           let d = data.split(' ');
            dt = d[index]
         }
         return dt
      },
      addDias(data, dias) {
         let date = new Date(data);
         date.setDate(date.getDate() + dias);
         return this.setDataAtual(date)
      },
      compareObjects(obj1, obj2) {
         return JSON.stringify(obj1) === JSON.stringify(obj2);
      },

      getAbreviado(nomeCompleto) {
         // Só vai abreviar nomes com mais de 25 caracteres

         if (nomeCompleto.length > 15) {
            var nomes = nomeCompleto.split(' ');
            if (nomes.length > 2) {
               nomeCompleto = nomes[0]; // Primeiro nome não será abreviado.
               for (var i = 1; i < nomes.length - 1; i++) {
                  if (nomes[i].length > 3) {
                     nomeCompleto += ' ' + nomes[i][0].toUpperCase() + '.'
                  } else {
                     nomeCompleto += ' ' + nomes[i];
                  }
               }
               nomeCompleto += ' ' + nomes[nomes.length - 1]; // Último nome não será abreviado
            }
         }
         return nomeCompleto;
      },
      strArray(str) {
         if (this.validarCampo(str)) {
            return str.split('/')
         } else {
            return []
         }
      },
      setRotinas(editando){
         localStorage.setItem('acesso', editando ? 'A' : 'I')
      }
   },
}
