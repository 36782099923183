/* eslint-disable */
import {createStore} from 'vuex'
import geral from './modules/geral'
import token from "./modules/token";
import linhas from "./modules/linhas";
import carros from "./modules/carros";
import monitor from "./modules/monitor";
import usuario from './modules/usuarios'
import setores from "./modules/setores";
import horarios from "./modules/horarios";
import empresas from "./modules/empresas";
import relatorio from "./modules/relatorio";
import motoristas from "./modules/motoristas";
import configDash from "./modules/configDash";
import grupo_acessos from "./modules/grupo_acessos";
import abastecimentos from "./modules/abastecimentos";
import auditoria from "./modules/auditoria";

export default createStore({
  modules: {
    geral,
    token,
    carros,
    linhas,
    setores,
    usuario,
    monitor,
    horarios,
    empresas,
    auditoria,
    relatorio,
    motoristas,
    configDash,
    grupo_acessos,
    abastecimentos,
  }
})
