/* eslint-disable */
import {v4 as uuidV4} from 'uuid';

export class Abastecimento {
   constructor() {
      this.abastecimento_id = uuidV4()
      this.abastecimento_em_transito = false
      this.carro_id = ''
      this.dt_abastecimento = ''
      this.horario_id = ''
      this.km_abastecimento = ''
      this.qtd_litros_arla32 = 0
      this.qtd_litros_diesel = 0
      this.usuario_id = ''
      this.setor_id =''
      this.abastecimentos_motoristas = []
   }
}
