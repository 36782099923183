/* eslint-disable */
import {lista, pegaDados, processar} from '../../services/GenericAPI'
import {EventBus} from '../../helpers/event-bus'
import {Setor} from "../../models/setor";
import {SetorSCH} from "../../search/SetorSCH"
import {Filtro} from "../../models/filtro/filtro";

const url = '/setor';

const state = {
  all: [],
  allList: [],
  filtro: new Filtro(),
  setor: new Setor()
};

const getters = {
  pegaFiltro: (state) => {
    return state.filtro
  },
  listaSetores: (state) => {
    return state.all
  },
  listaAllSetor: (state) => {
    return state.allList
  },
  pegarSetor: (state) => {
    return state.setor
  },
};

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['nome', 'setor_id', 'abreviatura'],
      '%'+search+'%',
      'ilike'
    )
  },
  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', SetorSCH(perPage))
  },

  setSetor({commit}, setor) {
    commit('SET_SETOR', setor)
  },

  async setAllSetores ({ commit },{onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data;
      commit('SET_SETORES', list);
      onSucesso()
    } catch (error) {
      onErro()
    }
  },

  async setAllListSetor ({ commit },{onSucesso}) {
    try {
      const list = (await lista(url+'/all')).data;
      commit('SET_ALL_SETORES', list);
      onSucesso()
    }   catch (error) {
      console.log('ERROR: ',error);
    }

  },

  async processarSetor ({ commit }, { setor, acao,onSucesso,onErro }) {
    try {
      let res = await processar(url + (acao==='gravar' ? '/commit' : ''), setor, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }
  }
};

const mutations = {
  SET_SETORES (state, setor) {
    state.all = setor
  },
  SET_ALL_SETORES (state, setor) {
    state.allList = setor
  },
  SET_SETOR (state, setor) {
    state.setor = setor
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
