/* eslint-disable */
import {lista, pegaDados, pegaLista, processar} from '../../services/GenericAPI'
import {EventBus} from '../../helpers/event-bus'
import {Motorista} from "../../models/motorista";
import {MotoristaSCH} from "../../search/MotoristaSCH"
import {Filtro} from "../../models/filtro/filtro";
import {getBaseSCH} from "../../search/BaseSCH";

const url = '/motorista';

const state = {
   all: [],
   allList: [],
   filtro: new Filtro(),
   motorista: new Motorista()
};

const getters = {
   pegaFiltro: (state) => {
      return state.filtro
   },
   listaMotoristas: (state) => {
      return state.all
   },
   listaAllMotorista: (state) => {
      return state.allList
   },
   pegarMotorista: (state) => {
      return state.motorista
   },
};

const actions = {
   setMotorista({commit}, motorista) {
      commit('SET_MOTORISTA', motorista)
   },
   searchItem({commit}, search) {
      state.filtro.filters.search = [];
      state.filtro.filters.addSearch(['motoristas.motorista_id','motoristas.nome', 'motoristas.nr_habilitacao','setores.nome'],
       '%' + search + '%',
       'ilike'
      )
   },
   async getItem({commit}, {id, onSucesso, onErro}) {
      try {
         let filtro = getBaseSCH('motorista_id', id, '=');
         const list = (await pegaDados(url + '/get', filtro)).data;
         commit('SET_MOTORISTA', list.data[0]);
         onSucesso()
      } catch (error) {
         onErro()
      }
   },

   setFiltro({commit}, perPage) {
      commit('SET_FILTRO', MotoristaSCH(perPage))
   },

   async setAllMotoristas({commit}, {onSucesso, onErro}) {
      try {
         const list = (await pegaDados(url + '/get', state.filtro)).data;
         commit('SET_MOTORISTAS', list);
         onSucesso()
      } catch (error) {
         onErro()
      }
   },

   async setAllListMotorista({commit}, {onSucesso}) {
      try {
         const list = (await lista(url + '/all')).data;
         commit('SET_ALL_MOTORISTAS', list);
         onSucesso()
      } catch (error) {
         console.log(error);
      }
   },
   async setListMotoristaSetor({commit}, {setor,onSucesso}) {
      try {
         const list = (await pegaLista(url + '/all', {setor_id: setor})).data;
         commit('SET_ALL_MOTORISTAS', list);
         onSucesso()
      } catch (error) {
         console.log(error);
      }
   },

   async processarMotorista({commit}, {motorista, acao, onSucesso, onErro}) {
      try {
         let res = await processar(url + (acao === 'gravar' ? '/commit' : ''), motorista, acao);
         this.dispatch('geral/setMsgSuccess', res.data.message);
         onSucesso()
      } catch (error) {
         this.dispatch('geral/setObjMsgError', error.response.data);
         onErro()
      }
   }
};

const mutations = {
   SET_MOTORISTAS(state, motorista) {
      state.all = motorista
   },
   SET_ALL_MOTORISTAS(state, motorista) {
      state.allList = motorista
   },
   SET_MOTORISTA(state, motorista) {
      state.motorista = motorista
   },
   SET_FILTRO(state, filtro) {
      state.filtro = filtro
   }
};

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations
}
