/* eslint-disable */
import axios from 'axios'

export const API = axios.create({
  headers: {
    ContentType: 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    UserId: sessionStorage.getItem('usuario_id'),
    Acesso: 'C',
    ControleAcessoId: '',
  },
  baseURL: process.env.VUE_APP_API_URL,
  BASE_URL: '/'
})

export function setRotina(rotina) {
  API.defaults.headers.Acesso = rotina
  API.defaults.headers.ControleAcessoId = sessionStorage.getItem('controle_acesso_id')
}

export function setToken(token, userID) {
  sessionStorage.setItem('token', token)
  API.defaults.headers.Authorization = `Bearer ${token}`
  API.defaults.headers.UserId = userID
}

