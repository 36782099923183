/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Carro {
  constructor () {
    this.carro_id = ''
    this.placa = ''
    this.empresa_id = ''
    this.setor_id = ''
    this.dt_aquisicao = ''
    this.chassi = ''
    this.modelo = ''
    this.tp_carroceria = ''
    this.renavan = ''
    this.capacidade = 0
    this.marca = ''
    this.ano_fabricacao = ''
    this.tp_motor = ''
    this.meta_consumo = ''
  }
}
