/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Usuario {
  constructor () {
    this.usuario_id = ''
    this.nome = ''
    this.senha = ''
    this.setor_id= ''
  }
}
