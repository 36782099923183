/* eslint-disable */
import {pegaDados, processaReport} from '../../services/GenericAPI'
import {SetorSCH} from "../../search/SetorSCH"
import {Filtro} from "../../models/filtro/filtro";

const url = '/relatorio';

const state = {
  pdf: '',
  filtro: new Filtro(),
};

const getters = {
  pegaFiltro: (state) => {
    return state.filtro
  },
  pegaPdf: (state) => {
    return state.pdf
  },
};

const actions = {
  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', SetorSCH(perPage))
  },
  async getPdf({commit}, {item,onSucesso, onErro}) {
    try {
      const retorno = (await processaReport(url + '/get_pdf', item))
      commit('SET_PDF', retorno)
      onSucesso(retorno)
    } catch (error) {
      onErro()
    }
    },
    async getTextoJson({commit}, {filtro,onSucesso, onErro}) {
    try {
      const retorno = (await pegaDados(url + '/get_txt', filtro)).data
      onSucesso(retorno.data)
    } catch (error) {
      console.log('ERROR: ',error);
      onErro()
    }
  },
};

const mutations = {
  SET_PDF(state, pdf) {
    state.pdf = pdf
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
