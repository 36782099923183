/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";


export const LinhaSCH = (perPage) => {
   var filtro = new Filtro()
   filtro.perPage = perPage
   filtro.fields = ['linhas.linha_id', 'destino', 'origem', 'distancia']
   filtro.addOrder('linhas.linha_id', 'asc')
   return filtro
}
