/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";


export const AbastecimentoSCH = (perPage) => {
   var filtro = new Filtro()
   filtro.perPage = perPage
   filtro.fields = ['*']
   filtro.addOrder('dt_lancamento','desc')
   // filtro.addOrder('usuario','asc')
   // filtro.addOrder('numero','asc')

   // var page = sessionStorage.getItem('page')
   // if (page !== undefined && page !== ''){
   //    filtro.page = JSON.parse(page)
   // }

   return filtro
}
