/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";


export const MotoristaSCH = (perPage) => {
   var filtro = new Filtro()
   filtro.perPage = perPage
   filtro.fields = ['motorista_id', 'motoristas.nome', 'nr_habilitacao', 'dt_habilitacao', 'dt_admissao', 'setores.nome as setor']
   filtro.join.addLeft('setores', 'setores.setor_id', 'motoristas.setor_id');
   filtro.addOrder('motoristas.motorista_id','asc')
   return filtro
}
