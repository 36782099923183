/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";


export const SetorSCH = (perPage)=> {
    var filtro = new Filtro()
    filtro.perPage = perPage
    filtro.fields = ['*']
   filtro.addOrder('setor_id','asc')
  return filtro
}
