/* eslint-disable */
export class Linha {
  constructor () {
    this.linha_id ='' // max: 10
    this.setor_id =''
    this.origem = ''
    this.destino = ''
    this.distancia = ''
  }
}
