/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";
export const AuditoriaSCH = (perPage)=> {
  let filtro = new Filtro();
  filtro.perPage = perPage;
  filtro.fields = ["*"];
  filtro.filters.addFilter('tipo','P', '=')
  // filtro.addOrder('dt_log','asc')
  // filtro.addDistinct('dt_log')
  // filtro.addDistinct('hr_log')
  return filtro
};
