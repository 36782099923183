import {createRouter, createWebHistory} from 'vue-router'

const router = createRouter({
   history: createWebHistory(process.env.BASE_URL),
   routes: [
      {path: '/', name: 'Login', component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')},
      {
         path: '/dash',
         name: 'Dash',
         component: () => import(/* webpackChunkName: "Dash" */ '../views/Dash.vue'),
         children: [
            {path: '/home', name: 'home', component: () => import(/* webpackChunkName: "Home" */ '../views/home/Home.vue')},
            {
               path: '/empresas',
               name: 'empresas',
               component: () => import(/* webpackChunkName: "Empresa" */ '../views/Cadastros/empresas/Empresas.vue')
            },
            {
               path: '/setores',
               name: 'Setor',
               component: () => import(/* webpackChunkName: "Setor" */ '../views/Cadastros/setores/Setores.vue')
            },
            {
               path: '/linhas',
               name: 'Linha',
               component: () => import(/* webpackChunkName: "Linha" */ '../views/Cadastros/linha/Linhas.vue')
            },
            {
               path: '/motoristas',
               name: 'Motorista',
               component: () => import(/* webpackChunkName: "Motorista" */ '../views/Cadastros/motorista/Motoristas.vue')
            },
            {
               path: '/carros',
               name: 'Carro',
               component: () => import(/* webpackChunkName: "Carro" */ '../views/Cadastros/carros/Carro.vue')
            },
            {
               path: '/usuarios',
               name: 'Usuario',
               component: () => import(/* webpackChunkName: "Usuario" */ '../views/Cadastros/usuarios/Usuario.vue')
            },
            {
               path: '/grupo_acesso',
               name: 'Grupo de Acesso',
               component: () => import(/* webpackChunkName: "GrupoAcesso" */ '../views/Configuracao/grupoAcesso/GrupoAcesso.vue')
            },
            {
               path: '/consumo_diesel',
               name: 'Consumo Diesel',
               component: () => import(/* webpackChunkName: "ConsumoDiesel" */ '../views/relatorios/ConsumoDiesel.vue')
            },
            {
               path: '/controle_consumo',
               name: 'Controle_consumo',
               component: () => import(/* webpackChunkName: "Relatorio" */ '../views/relatorios/Relatorio.vue')
            },
            {
               path: '/horarios',
               name: 'Horarios',
               component: () => import(/* webpackChunkName: "Horarios" */ '../views/Cadastros/horario/Horario.vue')
            },
            {
               path: '/horarios/cadastro',
               name: 'horariosCadastro',
               component: () => import(/* webpackChunkName: "HorariosCadastro" */ '../views/Cadastros/horario/HorarioCadastro.vue')
            },
            {
               path: '/abastecimento',
               name: 'Abastecimento',
               component: () => import(/* webpackChunkName: "Abastecimento" */ '../views/Configuracao/abastecimento/Abastecimento.vue')
            },
            {
               path: '/abastecimento/cadastro',
               name: 'AbastecimentoCaddastro',
               component: () => import(/* webpackChunkName: "AbastecimentoCadastro" */ '../views/Configuracao/abastecimento/AbastecimentoCadastro.vue')
            },
            {
               path: '/auditoria',
               name: 'Auditoria',
               component: () => import(/* webpackChunkName: "Auditoria" */ '../views/Outros/auditoria/Auditoria.vue')
            },
         ]
      },
      {
         path: '/:pathMatch(.*)*',
         name: 'not-found',
         component: () => import(/* webpackChunkName: "404" */ '../views/NotFound/NotFound.vue')
      },

   ]
});

export default router
