/* eslint-disable */
const state = {
  isdarkTheme: false,
  darkTheme: 'light' || sessionStorage.getItem('darkTheme'),
  drawer: true,
  modal: false,
  sidebarId: 'principal',
  miniVariant: false,
  sidebarColor: 'rgba(0, 0, 0, 0.8)',
  modalfiltro: false,
  search: '',
  dialogError: false,
  objMsgError: null,
  snackSuccess: false,
  btnDownload: false,
  tab: '',
  msgSuccess: '',
  background: '',
  checkboxImg: 1,
  chktema: true,
  showFilter: false,
  swatches: '#000000'
}

const getters = {
  btnAdd: state => {
    return state.btnAdd
  },
  getSearch: state => {
    return state.search
  },
  getIsDarkTheme: state => {
    return state.isdarkTheme
  },
  getDarkTheme: state => {
    return state.darkTheme
  },
  pegarShowFilter: state => {
    return state.showFilter
  },
}

const actions = {
  toggleDarkTheme ({ commit }) {
    commit('SET_ISDARKTHEME', !state.isdarkTheme)
    commit('SET_DARKTHEME', state.isdarkTheme ? 'dark' : 'light')
  },
  setDarkTheme ({ commit }, valor) {
    commit('SET_DARKTHEME', valor ? 'dark' : 'light')
    commit('SET_ISDARKTHEME', valor)
  },
  toggledrawer ({ commit }) {
    commit('SET_DRAWER', !state.drawer)
  },
  setShowFilter({commit}, valor) {
    commit('SET_SHOWFILTER', valor)
  },
  setModal ({ commit }, valor) {
    commit('SET_MODAL', valor)
  },

  setModalFiltro ({ commit }, valor) {
    commit('SET_MODALFILTRO', valor)
  },
  toggleModalFiltro ({ commit }) {
    commit('SET_MODALFILTRO', !state.modalfiltro)
  },
  setMiniVariant ({ commit }, valor) {
    commit('SET_MINIVARIANT', valor)
  },
  toggleMiniVariant ({ commit }) {
    commit('SET_MINIVARIANT', !state.miniVariant)
  },

  setSearch ({ commit }, valor) {
    sessionStorage.setItem('page', '1')
    commit('SET_SEARCH', valor)
  },
  toggleDialogError ({ commit }) {
    commit('SET_DIALOGERROR', !state.dialogError)
  },
  setObjMsgError ({ commit }, valor) {
    commit('SET_OBJMSGERROR', valor)
    commit('SET_DIALOGERROR', true)
  },
  toggleSnackSuccess ({ commit }) {
    commit('SET_SNACKSUCCESS', !state.snackSuccess)
  },
  setMsgSuccess ({ commit }, valor) {
    commit('SET_MSGSUCCESS', valor)
    commit('SET_SNACKSUCCESS', true)
  },
  setBtnDownload ({ commit }, valor) {
    commit('SET_BTNDOWNLOAD', valor)
  },
  setTab ({ commit }, valor) {
    commit('SET_TAB', valor)
  },
  setBackground ({ commit }, valor) {
    commit('SET_BACKGROUND', valor)
  },
  setCheckBoxImg ({ commit }, valor) {
    commit('SET_CHECKBOXIMG', valor)
  },
  setChkTema ({ commit }, valor) {
    commit('SET_CHKTEMA', valor)
  },
  setSwatches ({ commit }, valor) {
    commit('SET_SWATCHES', valor)
  }
}

const mutations = {
  SET_ISDARKTHEME(state, payload) {
    sessionStorage.setItem('isdarkTheme', payload)
    state.isdarkTheme = payload
  },
  SET_DARKTHEME(state, payload) {
    sessionStorage.setItem('darkTheme', payload)
    state.darkTheme = payload
  },
  SET_DRAWER(state, payload) {
    state.drawer = payload
  },
  SET_SHOWFILTER(state, payload) {
    state.showFilter = payload
  },
  SET_MODAL (state, payload) {
    state.modal = payload
  },
  SET_TOOLBARSEARCH (state, payload) {
    state.toolbarsearch = payload
  },
  SET_TOOLBARSAVE (state, payload) {
    state.toolbarsave = payload
  },
  SET_SIDEBARID (state, payload) {
    state.sidebarId = payload
  },
  SET_MODALFILTRO (state, payload) {
    state.modalfiltro = payload
  },
  SET_MINIVARIANT (state, payload) {
    state.miniVariant = payload
  },
  SET_SIDEBARCOLOR (state, payload) {
    state.sidebarColor = payload
  },
  SET_SEARCH (state, payload) {
    state.search = payload
  },
  SET_TOOLBARDASH (state, payload) {
    state.toolbarDash = payload
  },
  SET_DIALOGERROR (state, payload) {
    state.dialogError = payload
  },
  SET_OBJMSGERROR (state, payload) {
    state.objMsgError = payload
  },
  SET_SNACKSUCCESS (state, payload) {
    state.snackSuccess = payload
  },
  SET_MSGSUCCESS (state, payload) {
    state.msgSuccess = payload
  },
  SET_BTNDOWNLOAD (state, payload) {
    state.btnDownload = payload
  },
  SET_TAB (state, payload) {
    state.tab = payload
  },
  SET_BACKGROUND (state, payload) {
    state.background = payload
  },
  SET_CHECKBOXIMG (state, payload) {
    state.checkboxImg = payload
  },
  SET_CHKTEMA (state, payload) {
    state.chktema = payload
  },
  SET_SWATCHES (state, payload) {
    state.swatches = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
