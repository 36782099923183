/* eslint-disable */
import {lista, pegaDados, processar} from '../../services/GenericAPI'
import {EventBus} from '../../helpers/event-bus'
import {AuditoriaSCH} from "../../search/AuditoriaSCH"
import {Filtro} from "../../models/filtro/filtro";
import {SetorSCH} from "../../search/SetorSCH";
import {getAllBaseSCH, getBaseSCH} from "../../search/BaseSCH";

const url = '/auditoria';

const state = {
  all: [],
  allList: [],
  filtro: new Filtro(),
  auditoria: []
};

const getters = {
  pegaFiltro: (state) => {
    return state.filtro
  },
  listaAuditorias: (state) => {
    return state.all
  },
  pegarAuditoria: (state) => {
    return state.auditoria
  },
};

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['nome','descricao','acao'],
     '%'+search+'%',
     'ilike'
    )
  },
  async getAuditoria({commit}, {referencia_id,onSucesso,onErro}) {

    try {
      let filtro = getAllBaseSCH('referencia_id', referencia_id, '=');
      const list = (await pegaDados(url+'/get', filtro)).data;
      commit('SET_AUDITORIA', list.data);
      onSucesso()
    } catch (error) {
      onErro()
    }

  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', AuditoriaSCH(perPage))
  },

  async setAllAuditorias ({ commit },{onSucesso,onErro}) {
    try {
       // console.log(state.filtro);
       const list = (await pegaDados(url+'/get', state.filtro)).data;
      // console.log('SET_AUDITORIAS:', list.data);
      commit('SET_AUDITORIAS', list.data);
      onSucesso()
    } catch (error) {
      onErro()
    }
  },

  setAuditoria({commit}, auditoria) {
    commit('SET_AUDITORIA', auditoria)
  },

};

const mutations = {
  SET_AUDITORIAS (state, auditoria) {
    state.all = auditoria
  },
  SET_AUDITORIA (state, auditoria) {
    state.auditoria = auditoria
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
