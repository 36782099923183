/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";


export const CarroSCH = (perPage) => {
   var filtro = new Filtro()
   filtro.perPage = perPage
   filtro.fields = ['placa', 'empresas.nome as empresa', 'setores.nome as setor', 'modelo', 'marca', 'ano_fabricacao']
   filtro.join.addLeft('setores', 'setores.setor_id', 'carros.setor_id');
   filtro.join.addLeft('empresas', 'empresas.empresa_id', 'carros.empresa_id');
   filtro.addOrder('carro_id','asc')
   return filtro
}
