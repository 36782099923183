/* eslint-disable */
import {lista, pegaDados, processar} from '../../services/GenericAPI'
import {EventBus} from '../../helpers/event-bus'
import {Empresa} from "../../models/empresa";
import {EmpresaSCH} from "../../search/EmpresaSCH"
import {Filtro} from "../../models/filtro/filtro";

const url = '/empresa';

const state = {
  all: [],
  allList: [],
  filtro: new Filtro(),
  empresa: new Empresa()
};

const getters = {
  pegaFiltro: (state) => {
    return state.filtro
  },
  listaEmpresas: (state) => {
    return state.all
  },
  listaAllEmpresas: (state) => {
    return state.allList
  },
  pegarEmpresa: (state) => {
    return state.empresa
  },
};

const actions = {
  setEmpresa({commit}, empresa) {
    commit('SET_EMPRESA', empresa)
  },
  searchItem({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['nome'],
      '%'+search+'%',
      'ilike'
    )
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', EmpresaSCH(perPage))
  },

  async setAllEmpresas ({ commit },{onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data;
      commit('SET_EMPRESAS', list);
      onSucesso()
    } catch (error) {
      onErro()
    }
  },

  async setAllListEmpresa ({ commit },{onSucesso}) {
    try {
      const list = (await lista(url+'/all')).data;
      commit('SET_ALL_EMPRESAS', list);
      onSucesso()
    }   catch (error) {
      console.log(error);
    }

  },

  async processarEmpresa ({ commit }, { empresa, acao,onSucesso,onErro }) {
    try {
      let res = await processar(url + (acao==='gravar' ? '/commit' : ''), empresa, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      onSucesso()
    } catch (error) {
      console.log(error.response.data);
      this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }
  }
};

const mutations = {
  SET_EMPRESAS (state, empresa) {
    state.all = empresa
  },
  SET_ALL_EMPRESAS (state, empresa) {
    state.allList = empresa
  },
  SET_EMPRESA (state, empresa) {
    state.empresa = empresa
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
