/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Motorista {
  constructor () {
    this.motorista_id = ''
    this.nome = ''
    this.nr_habilitacao = ''
    this.dt_habilitacao = ''
    this.dt_admissao= ''
    this.setor_id = ''
  }


}
