<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
			<div>
						<v-dialog id="dialogsmodal" style="z-index: 1000" v-model="Mostrar" :width="mobileModal(width)">
									<v-card id="modalcadastro" :theme="darkTheme">
												<v-card-title class="gradiente" primary-title>
															{{getTitulo()}}
															<slot name="close"></slot>
												</v-card-title>
												<v-card-text>
															<v-container>
																		<slot name="formulario"></slot>
															</v-container>
												</v-card-text>
												<v-divider></v-divider>
												<v-card-actions>
															<slot name="acao"></slot>
												</v-card-actions>
									</v-card>

									<v-overlay v-model="snackbar" persistent>
												<v-snackbar v-model="snackbar" vertical location="top" timeout="-1">
															<div class="text-subtitle-1 pb-2">Sair da tela</div>

															<p>Deseja cancelar o cadastro e sair da tela?</p>

															<template v-slot:setDarkTheme>
																		<v-btn color="gray" variant="text" @click="snackbar = !snackbar" aria-label="cancelar"> Cancelar</v-btn>
																		<v-btn color="indigo" variant="text" @click="confirmarSair()" aria-label="confirmar"> Confirmar</v-btn>
															</template>
												</v-snackbar>

									</v-overlay>
						</v-dialog>
			</div>
</template>

<script>
   /* eslint-disable */
   import miscMixin from '../helpers/misc'
   import {mapGetters, mapState} from "vuex";

   export default {
      mixins: [miscMixin],
      props: {
         titulo: {
            default: ''
         },
         modal: {
            default: false
         },
         fullscreen: {
            default: false
         },
         isCadastro: {
            default: false
         },
         width: {
            default: '50%'
         }
      },
      name: "ProModalCadastro",

      data() {
         return {
            snackbar: false,
            overlay: false,
            margem_left: '25',
            classe: '',
         }
      },
      methods: {
         commit() {
         },

         validarCampo(campo) {
            if (campo !== undefined && campo !== null && campo !== '') {
               return true
            } else {
               return false
            }
         },
         getTitulo() {
            if (this.validarCampo(this.titulo)) {
               return this.titulo
            } else {
               return this.$router.currentRoute.meta
            }
         },
         confirmarSair() {
            this.snackbar = false
            this.$emit('fechar', false)
         }
      },
      computed: {
         ...mapState('geral', {darkTheme: 'darkTheme'}),
         Mostrar: {
            get() {
               return this.modal
            },
            set() {
               return this.Mostrar
            }
         },
      },
      mounted() {
      },
      watch: {
         Mostrar: function () {
            if (this.Mostrar) {
               let _this = this
               var inter = setInterval(function () {
                  let elm = document.getElementById('modalcadastro')
                  if (elm !== null) {
                     elm.addEventListener('keydown', function (e) {
                        e = e || window.event;
                        const code = e.which || e.keyCode;

                        if (code === 27) {
                              _this.$emit('fechar', false)
                           e.preventDefault()
                        }
                     })
                  }

                  clearInterval(inter)
               }, 500)

            }
         }
      },
   }
</script>

<style scoped>
</style>
