/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";


export const EmpresaSCH = (perPage)=> {
    var filtro = new Filtro()
    filtro.perPage = perPage
    filtro.fields = ['*']
  return filtro
}
