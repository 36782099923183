/* eslint-disable */
import {lista, pegaDados, processar} from '../../services/GenericAPI'
import {Horario} from "../../models/horario";
import {HorarioSCH} from "../../search/HorarioSCH"
import {Filtro} from "../../models/filtro/filtro";
import {getBaseSCH} from "../../search/BaseSCH";

const url = '/horario';

const state = {
   all: [],
   allList: [],
   filtro: new Filtro(),
   horario: new Horario()
};

const getters = {
   pegaFiltro: (state) => {
      return state.filtro
   },
   listaHorarios: (state) => {
      return state.all
   },
   listaAllHorario: (state) => {
      return state.allList
   },
   pegarHorario: (state) => {
      return state.horario
   },
};

const actions = {
   setHorario({commit}, horario) {
      commit('SET_HORARIO', horario)
   },
   searchItem({commit}, search) {
      state.filtro.filters.search = [];
      state.filtro.filters.addSearch(['horarios.horario_id', 'setores.nome', 'destino', 'origem', 'distancia'],
       '%' + search + '%',
       'ilike'
      )
   },

   async getItem({commit}, {id, onSucesso, onErro}) {
      try {
         let filtro = getBaseSCH('horario_id', id, '=');
         const list = (await pegaDados(url + '/getLista', filtro)).data;
         commit('SET_HORARIO', list.data[0]);
         onSucesso()
      } catch (error) {
         onErro()
      }
   },


   setFiltro({commit}, perPage) {
      commit('SET_FILTRO', HorarioSCH(perPage))
   },

   async setAllHorarios({commit}, {onSucesso, onErro}) {
      try {
         const list = (await pegaDados(url + '/getLista', state.filtro)).data;
         commit('SET_HORARIOS', list);
         onSucesso()
      } catch (error) {
         console.log(error);
         onErro()
      }
   },

   async setAllListHorario({commit}, {onSucesso}) {
      try {
         const list = (await lista(url + '/all')).data;
         commit('SET_ALL_HORARIOS', list);
         onSucesso()
      } catch (error) {
         console.log(error);
      }

   },

   async processarHorario({commit}, {horario, acao, onSucesso, onErro}) {
      try {
         let res = await processar(url + (acao === 'gravar' ? '/commit' : ''), horario, acao);
         this.dispatch('geral/setMsgSuccess', res.data.message);
         onSucesso()
      } catch (error) {
         this.dispatch('geral/setObjMsgError', error.response.data);
         onErro()
      }
   },
   async processarHorarioLinha({commit}, {horario_linha, acao, onSucesso, onErro}) {
      try {
         console.log('processarHorarioLinha');
         let res = await processar(url + (acao === 'gravar' ? '/commit_horario_linha' : '/delete_horario_linha'), horario_linha, acao);
         // this.dispatch('geral/setMsgSuccess', res.data.message);
         onSucesso()
      } catch (error) {
         this.dispatch('geral/setObjMsgError', error.response.data);
         onErro()
      }
   }
};

const mutations = {
   SET_HORARIOS(state, horario) {
      state.all = horario
   },
   SET_ALL_HORARIOS(state, horario) {
      state.allList = horario
   },
   SET_HORARIO(state, horario) {
      state.horario = horario
   },
   SET_FILTRO(state, filtro) {
      state.filtro = filtro
   }
};

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations
}
