/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";


export const HorarioSCH = (perPage) => {
   var filtro = new Filtro()
   filtro.perPage = perPage
   filtro.fields = ['horarios.horario_id', 'horarios.setor_id', 'setores.nome', 'hr_chegada','meta_motor_dianteiro','meta_motor_traseiro']
   filtro.join.addLeft('setores', 'setores.setor_id', 'horarios.setor_id');
   filtro.addOrder('horarios.hr_chegada', 'asc')
   filtro.addOrder('setores.nome', 'asc')
   return filtro
}
