/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";


export const UsuarioSCH = (perPage) => {
   var filtro = new Filtro()
   filtro.perPage = perPage
   filtro.fields = ['usuarios.nome', 'setores.nome as setor']
   filtro.join.addLeft('setores', 'setores.setor_id', 'usuarios.setor_id');
   filtro.addOrder('usuario_id','asc')
   return filtro
}
